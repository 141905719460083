/* App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.app {
  @apply p-8 w-full max-w-none font-sans bg-gray-100 min-h-screen;
  font-family: 'Montserrat', monospace;
}

.app.dark {
  @apply bg-gray-900 text-white;
}

.main-content {
  @apply flex flex-col md:flex-row justify-between gap-8;
}

.task-input-section {
  @apply mb-8 md:w-1/2; 
}

/* Prioritized Lists */
.prioritized-lists {
  @apply w-full transition-colors duration-300 ease-in-out;
  /* Removed padding, shadow, and rounded corners to make it less visually invasive */
}

/* Schedule Task Card */
.schedule-task-card {
  @apply flex items-center justify-between p-4 mb-4 rounded-lg shadow-md transition-colors duration-300 ease-in-out;
}

.schedule-task-card.dark {
  @apply bg-gray-700 text-white;
}

.schedule-task-card.light {
  @apply bg-white text-gray-800;
}

/* Simple Task Card (without justify-between) */
.simple-task-card {
  @apply flex items-center p-4 mb-4 rounded-lg shadow-md transition-colors duration-300 ease-in-out;
}

.simple-task-card.dark {
  @apply bg-gray-700 text-white;
}

.simple-task-card.light {
  @apply bg-white text-gray-800;
}

.delete-task {
  @apply absolute top-2 right-2 text-red-500 hover:text-red-700 bg-transparent border-none text-xl cursor-pointer transition-colors duration-300 ease-in-out dark:text-white dark:hover:text-gray-400;
}

.checkbox {
  @apply mr-2 h-4 w-4 text-blue-500;
}

.category-title {
  @apply text-xl font-semibold mb-4 mt-6 transition-colors duration-300 dark:text-white;
}

.empty-state {
  @apply text-gray-500 text-sm italic transition-colors duration-300 dark:text-gray-400;
}

.task-heading {
  @apply text-3xl font-bold mb-6 text-gray-800 dark:text-white;
}

/* Add Task Button */
.add-task-button {
  @apply bg-gray-800 hover:bg-gray-900 text-white px-6 py-3 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 font-bold dark:bg-gray-600 dark:hover:bg-gray-700;
}

/* Task Input Container */
.task-input {
  @apply flex items-center w-full rounded-md border border-gray-300 dark:border-gray-500 transition-colors duration-300;
}

/* Task Input Textarea Styling */
.task-input textarea {
  @apply flex-grow p-3 shadow-sm border-none rounded-none rounded-l-md focus:outline-none focus:ring-2 transition-colors duration-300 ease-in-out;
  @apply dark:bg-gray-800 dark:text-white dark:focus:ring-blue-300;
}

/* Add Button Styling (within Task Input) */
.task-input button {
  @apply bg-gray-800 text-white p-3 shadow-md rounded-none rounded-r-md transition duration-300 ease-in-out transform hover:scale-105 font-bold;
  @apply dark:bg-gray-600 dark:hover:bg-blue-600;
}

.task-item {
  @apply p-4 border border-gray-300 rounded-lg mb-4 bg-white shadow-md transition-transform duration-300 ease-in-out transform hover:scale-105 transition-colors dark:bg-gray-700 dark:text-white dark:border-gray-500;
  touch-action: none;
}

/* Swipe Instructions */
.swipe-instructions {
  @apply text-sm mb-4 text-gray-500 flex justify-between px-2 dark:text-gray-400;
}